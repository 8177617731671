<template>
  <div :class="'relative'">
    <label
      v-if="label"
      :class="['block mb-1 font-manrope text-sm text-neutral-600 font-medium']"
    >
      {{ label }}
      <span v-if="required" class="text-danger">*</span>
    </label>
    <div class="relative flex items-start">
      <span v-if="leftIcon" class="absolute left-4 top-3.5">
        <slot name="leftIcon">
          <component :is="leftIcon" :size="22" class="text-slate-500" />
        </slot>
      </span>

      <input
        v-if="showInputField"
        v-model="inputValue"
        :required="required"
        :readonly="readonly"
        :class="[
          ...commonInputClasses,
          { 'pl-12': leftIcon, 'pr-12': rightIcon },
        ]"
        :type="type"
        :placeholder="placeholder"
      >

      <select
        v-if="type === 'select'"
        v-model="inputValue"
        :required="required"
        :class="[
          ...commonInputClasses,
          { 'pl-12': leftIcon, 'pr-12': rightIcon },
        ]"
      >
        <option v-if="options && options.length" disabled value>
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>

      <textarea
        v-if="type === 'textarea'"
        v-model="inputValue"
        :required="required"
        :class="[
          ...commonInputClasses,
          {
            'pl-12': leftIcon,
            'pr-12': rightIcon,
            'min-h-24': type === 'textarea',
          },
        ]"
        :placeholder="placeholder"
      />

      <span
        v-if="rightIcon"
        class="absolute right-4 hover:opacity-75 top-4"
        @click="$emit('iconClick')"
      >
        <component :is="rightIcon" class="text-slate-500" />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineEmits<{
  (e: "iconClick"): void;
}>();

interface Props {
  label?: string;
  required?: boolean;
  readonly?: boolean;
  placeholder?: string;
  leftIcon?: string | object | (() => void);
  rightIcon?: string | object;
  type:
    | "text"
    | "email"
    | "select"
    | "textarea"
    | "password"
    | "number"
    | "date";
  disabled?: boolean;
  options?: { value: string | number; label: string }[];
  inputClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  required: false,
  readonly: false,
  placeholder: "",
  leftIcon: '',
  rightIcon: '',
  options: () => [],
  inputClass: "",
});

const commonInputClasses = [
  "w-full py-3.5 px-4 font-manrope font-semibold text-sm rounded-xl text-neutral-600 transition-all bg-blue-50",
  "border rounded shadow-sm transition-all focus:border-primary outline-none placeholder:font-manrope placeholder:text-sm",
  props.inputClass,
];

const showInputField = computed(
  () => props.type !== "select" && props.type !== "textarea"
);

const inputValue = defineModel<string | number>();
</script>
